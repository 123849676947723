import React, { useCallback, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import styled from 'styled-components';

import { animation, colors, header } from '../../config';
import { media } from '../../utils/responsive';
import GridContainer from '../grid/grid-container';
import HeaderOffset from './header-offset';
import useHeader from './header.hook';
import Meteors from './meteors';

const StyledHeroHeader = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${header.heroHeight.small - 5}px;
  position: relative;
  overflow: visible;
  background: ${colors.backgroundBlack};
  margin-bottom: 5px;

  ${media.medium`
    height: ${header.heroHeight.medium - 5}px;
  `}

  h1,
  h2 {
    width: calc(100% - ${header.heroPicOffset.small}px);
    display: block;
    margin: 0;
    padding: 0;
    color: #fff;
    text-transform: uppercase;
    word-break: keep-all;
    z-index: 2;

    ${media.medium`
      width: calc(100% - ${header.heroPicOffset.medium}px);
    `}
  }

  h1 {
    font-size: 36px;
    font-weight: 900;
    margin-bottom: 8px;
    animation: ${animation.fadeInHorizontal('-10px')} 0.5s ease-out;
    word-wrap: normal;

    ${media.medium`
      font-size: 70px;
      margin-bottom: 12px;
    `}

    ${media.large`
      font-size: 112px;
      margin-bottom: 16px;
    `}
  }

  h2 {
    font-size: 20px;
    font-weight: 700;
    animation: ${animation.fadeInHorizontal('-20px')} 0.2s ease-out 0.3s both;
    word-wrap: normal;

    br {
      display: none;
    }

    ${media.medium`
      font-size: 40px;
    `}

    ${media.large`
      font-size: 60px;

      br {
        display: initial;
      }
    `}
  }
`;

const HeroGridContainer = styled(GridContainer)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  height: 100%;
`;

const HeroPic = styled(Image)`
  width: ${header.heroPicWidth.small}px;
  bottom: 0;
  right: -${header.heroPicOffset.small}px;
  z-index: 1;

  ${media.medium`
    width: ${header.heroPicWidth.medium}px;
    right: -${header.heroPicOffset.medium}px;
  `}
`;

const HeroBottomSVG = styled.svg`
  width: 100%;
  position: absolute;
  bottom: -5px;
  z-index: 2;
`;

export default function HeroHeader() {
  const data = useStaticQuery(graphql`
    query HeroHeaderQuery {
      mobileHeroPic: file(absolutePath: { regex: "/hero-pic.png/" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      desktopHeroPic: file(absolutePath: { regex: "/hero-pic.png/" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  const sources = [
    {
      ...data.mobileHeroPic.childImageSharp.fluid,
      media: '(max-width: 768px)',
    },
    {
      ...data.desktopHeroPic.childImageSharp.fluid,
      media: '(min-width: 768px)',
    },
  ];
  const [headerHeight] = useState(header.heroHeight.medium);
  const { isDark, setIsDark } = useHeader();
  const handleSettingIsDarkHeader = useCallback(() => {
    const scrollPosition = document.documentElement.scrollTop;

    if (scrollPosition >= headerHeight && (isDark || isDark === null)) {
      setIsDark(false);
    } else if (scrollPosition < headerHeight && !isDark) {
      setIsDark(true);
    }
  }, [headerHeight, isDark]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    function componentDidMount() {
      document.addEventListener('scroll', handleSettingIsDarkHeader);
      handleSettingIsDarkHeader();

      return function componentDidUnmount() {
        document.removeEventListener('scroll', handleSettingIsDarkHeader);
      };
    },
    [handleSettingIsDarkHeader]
  );

  return (
    <StyledHeroHeader headerHeight={headerHeight}>
      <HeaderOffset />

      <HeroGridContainer>
        <Meteors />

        <h1>Web artisan</h1>
        <h2>
          Specialized in <br /> front-end
        </h2>

        <HeroPic fluid={sources} style={{ position: 'absolute' }} />
      </HeroGridContainer>

      <HeroBottomSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 40">
        <path d="M1920 40H0L1920 0v40z" fill="#fff" />
      </HeroBottomSVG>
    </StyledHeroHeader>
  );
}

import React, { useRef } from 'react';
import styled, { css } from 'styled-components';

import { animation } from '../../config';
import { media } from '../../utils/responsive';
import BaseButtonLink from '../button-link';
import Section from '../section/section';
import SectionText from '../section/section-text';
import SectionTitle from '../section/section-title';
import useIsInViewport from './use-is-in-viewport';

const ContactSectionContainer = styled(Section)`
  align-items: center;
`;

const ContactTitle = styled(SectionTitle)`
  width: 100%;
  text-align: center;
  margin-bottom: 48px;

  ${media.medium`
    margin-bottom: 64px;
  `}
`;

const Divider = styled.hr`
  width: 65px;
  height: 1px;
  background: rgba(0, 0, 0, 0.5);
  margin: 48px 0;
  opacity: 0;
  ${({ animate = false }) =>
    animate &&
    css`
      animation: ${animation.fadeIn} 0.6s ease-out 0.7s both;
    `}

  ${media.medium`
    margin: 64px 0;
  `}
`;

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const ButtonAnchor = BaseButtonLink.withComponent('a');

const SocialButton = styled(ButtonAnchor)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  padding: 14px;
  margin: 16px 16px 0 16px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  opacity: 0;
  ${({ animate = false, animationOrder = 1 }) => {
    if (!animate) {
      return null;
    }

    const delay = 0.6 + animationOrder * 0.2;

    return css`
      animation: ${animation.fadeInHorizontal('10px')} 0.6s ease-out ${delay}s
        both;
    `;
  }}

  &::before {
    content: '';
    display: block;
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: ${animation.transition};
    border-radius: 50%;
  }

  &:hover::before,
  &:focus::before {
    opacity: 1;
  }

  ${media.large`
    width: 96px;
    height: 96px;
    padding: 24px;
    margin: 28px 28px 0 28px;
  `}
`;

const SocialSVG = styled.svg`
  display: flex;
  width: 100%;
`;

const GitHubButton = styled(SocialButton)`
  &,
  &:hover,
  &:focus {
    background: linear-gradient(45deg, #000000, rgba(0, 0, 0, 0.66));
  }
`;

const TwitterButton = styled(SocialButton)`
  &,
  &:hover,
  &:focus {
    background: linear-gradient(45deg, #1d2c7b, #1da1f2);
  }
`;

const LinkedInButton = styled(SocialButton)`
  padding: 19px;

  &,
  &:hover,
  &:focus {
    background: linear-gradient(45deg, #00092d, #0077b5);
  }

  ${media.large`
    padding: 30px;
  `}
`;

const ContactText = styled(SectionText)`
  text-align: center;
  opacity: 0;
  ${({ animate = false }) =>
    animate &&
    css`
      animation: ${animation.fadeIn} 0.6s ease-out 0.9s both;
    `}
`;

// eslint-disable-next-line no-unused-vars
const ButtonLinkWithoutAnimate = ({ animate, ...rest }) => (
  <BaseButtonLink {...rest} />
);

const ButtonLink = styled(ButtonLinkWithoutAnimate)`
  opacity: 0;
  ${({ animate = false }) =>
    animate &&
    css`
      animation: ${animation.fadeIn} 0.6s ease-out 0.3s both;
    `}
`;

export default function ContactSection() {
  const sectionRef = useRef(null);
  const isInViewport = useIsInViewport(sectionRef);

  return (
    <ContactSectionContainer gridDirection="vertical" ref={sectionRef}>
      <ContactTitle animate={isInViewport}>
        Wanna get in touch with me?
      </ContactTitle>
      <ButtonLink
        animate={isInViewport}
        to={'/contact/'}
        backgroundColor="blue"
        isRounded={true}
      >
        Contact me →
      </ButtonLink>

      <Divider animate={isInViewport} />

      <ContactText animate={isInViewport}>You can also find me on</ContactText>

      <SocialContainer>
        <GitHubButton
          href="https://github.com/mertindervish"
          target="_blank"
          rel="noopener"
          aria-label="Github profile"
          isRounded={true}
          animate={isInViewport}
          animationOrder={1}
        >
          <SocialSVG viewBox="0 0 56 56">
            <path
              fill="#fff"
              d="M21.1,54.6c0-0.7,0-2.5,0-4.9c-7.8,1.7-9.4-3.8-9.4-3.8c-1.3-3.3-3.1-4.2-3.1-4.2c-2.5-1.8,0.2-1.7,0.2-1.7 c2.8,0.2,4.3,3,4.3,3c2.5,4.4,6.6,3.1,8.1,2.4c0.3-1.9,1-3.1,1.8-3.8c-6.2-0.7-12.8-3.2-12.8-14.2c0-3.1,1.1-5.7,2.9-7.7 c-0.3-0.7-1.2-3.6,0.3-7.6c0,0,2.4-0.8,7.7,2.9c2.2-0.6,4.6-1,7-1c2.4,0,4.8,0.3,7,1c5.3-3.7,7.7-2.9,7.7-2.9c1.5,4,0.6,6.9,0.3,7.6 c1.8,2,2.9,4.6,2.9,7.7c0,11-6.5,13.5-12.8,14.2c1,0.9,1.9,2.6,1.9,5.3c0,3.8,0,6.9,0,7.9c0,0.8,0.5,1.7,1.9,1.4 C48,52.1,56,41.4,56,28.7C56,12.9,43.5,0,28,0C12.5,0,0,12.9,0,28.7C0,41.4,8,52.2,19.2,56C20.5,56.2,21.1,55.3,21.1,54.6z"
            />
          </SocialSVG>
        </GitHubButton>
        <TwitterButton
          href="https://twitter.com/mertindervish"
          target="_blank"
          rel="noopener"
          aria-label="Twitter profile"
          isRounded={true}
          animate={isInViewport}
          animationOrder={2}
        >
          <SocialSVG viewBox="0 0 56 45.5">
            <path
              fill="#fff"
              d="M56,5.4c-2.1,0.9-4.3,1.5-6.6,1.8c2.4-1.4,4.2-3.7,5.1-6.4c-2.3,1.3-4.7,2.3-7.3,2.8C45.1,1.4,42.1,0,38.8,0 c-6.3,0-11.5,5.1-11.5,11.5c0,0.9,0.1,1.8,0.3,2.6c-9.6-0.5-18-5.1-23.7-12c-1,1.7-1.5,3.7-1.5,5.8c0,4,2,7.5,5.1,9.6 c-1.8-0.1-3.6-0.5-5.2-1.4v0.1c0,5.6,4,10.2,9.2,11.3c-1,0.3-2,0.4-3,0.4c-0.7,0-1.5-0.1-2.2-0.2c1.5,4.6,5.7,7.9,10.7,8 c-3.9,3.1-8.9,4.9-14.3,4.9c-0.9,0-1.8-0.1-2.7-0.2c5.1,3.3,11.1,5.2,17.6,5.2c21.1,0,32.7-17.5,32.7-32.7c0-0.5,0-1,0-1.5 C52.5,9.7,54.5,7.7,56,5.4z"
            />
          </SocialSVG>
        </TwitterButton>
        <LinkedInButton
          href="https://www.linkedin.com/in/mertindervish/"
          target="_blank"
          rel="noopener"
          aria-label="LinkedIn profile"
          isRounded={true}
          animate={isInViewport}
          animationOrder={3}
        >
          <SocialSVG viewBox="0 0 56 56">
            <path
              fill="#fff"
              d="M1.1,56h11.6V18.7H1.1V56z M6.8,13.8c3.8,0,6.8-3.1,6.8-6.9S10.6,0,6.8,0C3.1,0,0,3.1,0,6.9S3.1,13.8,6.8,13.8z M31.2,36.4 c0-5.2,2.4-8.4,7-8.4c4.3,0,6.3,3,6.3,8.4s0,19.6,0,19.6H56c0,0,0-13.6,0-23.6s-5.7-14.8-13.6-14.8c-7.9,0-11.2,6.2-11.2,6.2v-5	H20.1V56h11.1C31.2,56,31.2,42.1,31.2,36.4z"
            />
          </SocialSVG>
        </LinkedInButton>
      </SocialContainer>
    </ContactSectionContainer>
  );
}

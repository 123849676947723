import { useCallback, useEffect, useState } from 'react';

export default function useIsInViewport(sectionRef) {
  const [isInViewport, setIsInViewport] = useState(false);
  const handleIsInViewport = useCallback(() => {
    if (!sectionRef.current) {
      return;
    }

    if (isInViewport) {
      return;
    }

    const bounding = sectionRef.current.getBoundingClientRect();
    const viewportHeight =
      window.innerHeight || document.documentElement.clientHeight;

    if (
      bounding.top < viewportHeight * (70 / 100) ||
      bounding.bottom <= viewportHeight
    ) {
      setIsInViewport(true);
    }
  }, [isInViewport, sectionRef]);

  useEffect(
    function componentDidMount() {
      document.addEventListener('scroll', handleIsInViewport);
      handleIsInViewport();

      return function componentDidUnmount() {
        document.removeEventListener('scroll', handleIsInViewport);
      };
    },
    [handleIsInViewport]
  );

  return isInViewport;
}

import styled, { css } from 'styled-components';

import GridContainer from '../../components/grid/grid-container';
import { media } from '../../utils/responsive';

const SectionGridContainer = GridContainer.withComponent('section');

const Section = styled(SectionGridContainer)(
  ({ gridDirection = 'horizontal' }) => css`
    padding-top: 100px;
    padding-bottom: 100px;

    ${gridDirection === 'vertical' &&
    css`
      flex-direction: column;
    `}

    ${media.medium`
    padding-top: 154px;
    padding-bottom: 154px;
  `};
  `
);

export default Section;

import styled, { css } from 'styled-components';

import { animation } from '../../config';
import { media } from '../../utils/responsive';

const SectionTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 18px;
  font-size: 36px;
  line-height: 1.3;
  opacity: 0;
  ${({ animate = false }) =>
    animate &&
    css`
      animation: ${animation.fadeInHorizontal('-20px')} 0.6s ease-out forwards;
    `}

  ${media.large`
    font-size: 64px;
  `}

  br {
    display: none;

    ${media.medium`
      display: initial;
    `}
  }
`;

export default SectionTitle;

import styled from 'styled-components';

import { media } from '../../utils/responsive';

const SectionText = styled.p`
  margin: 0;
  font-weight: 500;

  ${media.large`
    font-size: 22px;
  `}
`;

export default SectionText;

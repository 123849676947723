import React from 'react';

import HeroHeader from '../components/header/hero-header';
import AboutSection from '../components/home-sections/about-section';
import BlogSection from '../components/home-sections/blog-section';
import ContactSection from '../components/home-sections/contact-section';
import SEO from '../components/seo';

export default function HomePage() {
  return (
    <>
      <SEO
        title="Home"
        jsonLd={{
          '@type': 'AboutPage',
        }}
      />

      <HeroHeader />
      <AboutSection />
      <BlogSection />
      <ContactSection />
    </>
  );
}

import React, { useRef } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import styled, { css } from 'styled-components';

import { animation, colors } from '../../config';
import { media } from '../../utils/responsive';
import BaseButtonLink from '../button-link';
import Grid from '../grid/grid';
import GridCell from '../grid/grid-cell';
import BaseSection from '../section/section';
import BaseSectionTitle from '../section/section-title';
import useIsInViewport from './use-is-in-viewport';

const Section = styled(BaseSection)`
  align-items: center;
  position: relative;
`;

const SectionTitle = styled(BaseSectionTitle)`
  margin-bottom: 48px;

  ${media.medium`
    margin-bottom: 64px;
  `}
`;

const BlogPosts = styled(Grid)`
  z-index: 2;
`;

const BlogPost = styled(GridCell)`
  opacity: 0;
  ${({ animate = false, animationOrder = 1 }) => {
    if (!animate) {
      return null;
    }

    const delay = 0.2 + animationOrder * 0.3;

    return css`
      animation: ${animation.fadeInHorizontal('20px')} 0.6s ease-out ${delay}s
        both;
    `;
  }}

  &:nth-child(n + 3) {
    display: none;

    ${media.large`display: block;`}
  }

  &:nth-child(n + 4) {
    display: none;
  }
`;

const BlogPostLink = styled(Link)`
  z-index: 2;
  overflow: hidden;
  background: #fff;
  color: ${colors.body};
  border-radius: 8px;
  transition: ${animation.transition};
  box-shadow: 0 3px 5px 0px rgba(0, 0, 0, 0.3);
  height: 360px;
  display: flex;
  flex-direction: column;

  &:hover {
    box-shadow: 0 14px 32px 0px rgba(0, 0, 0, 0.3);

    p {
      opacity: 1;
      height: 32px;
      transition-delay: 0.2s, 0s;
    }
  }
`;

const BlogPostImage = styled(Image)`
  flex: 1;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
`;

const BlogPostText = styled.div`
  background: #fff;
  padding: 24px;

  h3 {
    margin-top: 0;
    margin-bottom: 12px;
  }

  p {
    overflow: hidden;
    margin: 0;
    height: 0;
    opacity: 0;
    transition-property: opacity, height;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s, 0.2s;
    color: ${colors.lighterBody};
  }
`;

// eslint-disable-next-line no-unused-vars
const TransientBaseButtonLink = ({ animate, ...restProps }) => (
  <BaseButtonLink {...restProps} />
);

const ButtonLink = styled(TransientBaseButtonLink)`
  margin-top: 48px;
  opacity: 0;
  ${({ animate = false }) =>
    animate &&
    css`
      animation: ${animation.fadeIn} 0.6s ease-out 0.9s both;
    `}

  ${media.medium`
    margin-top: 64px;
  `}
`;

export default function BlogSection() {
  const data = useStaticQuery(graphql`
    query BlogPostsQuery {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { fields: { collection: { eq: "blog" } } }
        limit: 3
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 600, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const posts = data.allMarkdownRemark.edges;
  const sectionRef = useRef(null);
  const isInViewport = useIsInViewport(sectionRef);

  return (
    <Section ref={sectionRef} gridDirection="vertical">
      <BlogBackground />
      <Grid>
        <GridCell cellSize={12}>
          <SectionTitle animate={isInViewport}>Blog</SectionTitle>
        </GridCell>
      </Grid>

      <BlogPosts>
        {posts.map(({ node }, index) => {
          const title = node.frontmatter.title || node.fields.slug;

          return (
            <BlogPost
              key={node.fields.slug}
              animate={isInViewport}
              animationOrder={index + 1}
              cellSize={12}
              cellSizeMedium={6}
              cellSizeLarge={4}
            >
              <BlogPostLink to={node.fields.slug}>
                {node.frontmatter.thumbnail && (
                  <BlogPostImage
                    fluid={node.frontmatter.thumbnail.childImageSharp.fluid}
                  />
                )}
                <BlogPostText>
                  <h3>{title}</h3>
                  <p>Read →</p>
                </BlogPostText>
              </BlogPostLink>
            </BlogPost>
          );
        })}
      </BlogPosts>

      <ButtonLink
        to="/blog/"
        backgroundColor="white"
        isRounded={true}
        animate={isInViewport}
      >
        See all →
      </ButtonLink>
    </Section>
  );
}

const StyledSVG = styled.svg`
  display: flex;
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
`;

function BlogBackground() {
  return (
    <StyledSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1213.69">
      <defs>
        <linearGradient
          id="blog-background-gradient"
          x1="298.97"
          y1="940.77"
          x2="299.4"
          y2="941.54"
          gradientTransform="matrix(1874.04, 0, 0, -1184.64, -559332.89, 1115348.66)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ed1e79" />
          <stop offset="1" stopColor="#662d8c" />
        </linearGradient>
      </defs>
      <path
        id="Background-4"
        d="M1724.74,35.88C1475.45-152.29-25.27,443.69.32,801.51s1502.88,461.24,1745.52,392S1974,224.05,1724.74,35.88Z"
        fill="url(#blog-background-gradient)"
      />
    </StyledSVG>
  );
}

import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { colors } from '../../config';
import { media } from '../../utils/responsive';

const slideOffScreen = keyframes`
  from {
    opacity: 0;
    transform: rotate(45deg) translateY(-100px);
  }

  to {
    opacity: ${({ transparency = 1 }) => transparency};
    transform: rotate(45deg) translateY(0);
  }
`;

const Meteor = styled.div(
  ({ thickness = 10, length = 50, transparency = 1 }) => css`
    width: ${thickness}px;
    height: ${length}px;
    border-radius: ${thickness / 2}px;
    opacity: ${transparency};
    transform: rotate(45deg);
    position: absolute;

    ${media.medium`
      width: ${thickness * 2}px;
      height: ${length * 2}px;
      border-radius: ${thickness}px;
    `}
  `
);

const LightDarkPurpleMeteor = styled(Meteor)`
  top: 12%;
  left: 70%;
  background: linear-gradient(
    45deg,
    ${colors.meteorGradients.lightDarkPurple.from},
    ${colors.meteorGradients.lightDarkPurple.to}
  );
  animation: ${slideOffScreen} 0.4s ease-out 0.4s both;
`;

const LightDarkBlueMeteor = styled(Meteor)`
  top: 72%;
  left: -5%;
  background: linear-gradient(
    45deg,
    ${colors.meteorGradients.lightDarkBlue.from},
    ${colors.meteorGradients.lightDarkBlue.to}
  );
  animation: ${slideOffScreen} 0.25s ease-out 0.45s both;
`;

const PinkOrangeMeteor = styled(Meteor)`
  top: 5%;
  left: -7%;
  background: linear-gradient(
    45deg,
    ${colors.meteorGradients.pinkOrange.from},
    ${colors.meteorGradients.pinkOrange.to}
  );
  animation: ${slideOffScreen} 0.33s ease-out 0.55s both;
`;

const GreenYellowMeteor = styled(Meteor)`
  top: 59%;
  left: 67%;
  background: linear-gradient(
    45deg,
    ${colors.meteorGradients.greenYellow.from},
    ${colors.meteorGradients.greenYellow.to}
  );
  animation: ${slideOffScreen} 0.15s ease-out 0.6s both;
`;

const RedYellowMeteor = styled(Meteor)`
  top: 76%;
  left: 104%;
  z-index: 3;
  display: none;
  background: linear-gradient(
    45deg,
    ${colors.meteorGradients.redYellow.from},
    ${colors.meteorGradients.redYellow.to}
  );
  animation: ${slideOffScreen} 0.25s ease-out 0.65s both;

  ${media.large`
    display: block;
  `}
`;

const PurpleBlueMeteor = styled(Meteor)`
  top: -5%;
  left: 124%;
  background: linear-gradient(
    45deg,
    ${colors.meteorGradients.purpleBlue.from},
    ${colors.meteorGradients.purpleBlue.to}
  );
  animation: ${slideOffScreen} 0.35s ease-out 0.7s both;
`;

const PinkPurpleMeteor = styled(Meteor)`
  top: 9%;
  left: 39%;
  background: linear-gradient(
    45deg,
    ${colors.meteorGradients.pinkPurple.from},
    ${colors.meteorGradients.pinkPurple.to}
  );
  animation: ${slideOffScreen} 0.4s ease-out 0.75s both;
`;

const PinkYellowMeteor = styled(Meteor)`
  top: 55%;
  left: 40%;
  background: linear-gradient(
    45deg,
    ${colors.meteorGradients.pinkYellow.from},
    ${colors.meteorGradients.pinkYellow.to}
  );
  animation: ${slideOffScreen} 0.2s ease-out 0.8s both;
`;

export default function Meteors() {
  return (
    <>
      <LightDarkPurpleMeteor thickness="55" length="186" transparency={0.15} />
      <LightDarkBlueMeteor thickness="15" length="84" transparency={0.2} />
      <PinkOrangeMeteor thickness="18" length="260" transparency={0.54} />
      <GreenYellowMeteor thickness="21" length="91" transparency={0.65} />
      <RedYellowMeteor thickness="33" length="140" transparency={4} />
      <PurpleBlueMeteor thickness="14" length="94" transparency={0.26} />
      <PinkPurpleMeteor thickness="58" length="58" transparency={0.77} />
      <PinkYellowMeteor thickness="32" length="125" transparency={0.7} />
    </>
  );
}

import React from 'react';
import { Link } from 'gatsby';

import Button from './form/button';

// eslint-disable-next-line no-unused-vars
const TransientLink = ({ backgroundColor, isRounded, ...restProps }) => (
  <Link {...restProps} />
);

const ButtonLink = Button.withComponent(TransientLink);

export default ButtonLink;
